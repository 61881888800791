import { Grid, Typography, Box } from "@mui/material";
import View3D from "@egjs/react-view3d";
import "@egjs/react-view3d/css/view3d-bundle.min.css";
import React, { useContext, useState } from "react";
import ProductDetailsHead from "./product-head-button";
import { productDetailsMainStyles } from "../../styles/product-detail-style";
import ImageSlick from "../commons/slick-slider";
import _ from "lodash";
import { imageHandle } from "../../services/image-handle.service";
import EditAndDelete from "../commons/edit-del.component";
import { role } from "../../services/session";
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import Context from "../../hooks/context";

const ProductDetailsComponent = ({
  data,
  handleImageModal,
  modal,
  setModal,
  image,
  handleClickCompare,
  handleDeleteProduct,
  handleEditProduct
}: any) => {
  const roles: any = role();
  const thumbImages = imageHandle(data?.imageURLs)?.thumbImage;

  const [selectedImage, setSelectedImage] = useState(thumbImages?.[0]);
  // const contextData: any = useContext(Context);

  return (
    <>
      <Grid sx={productDetailsMainStyles} container  >
        <Box id="icon-button-box" className="icon-button-box">
          <ProductDetailsHead page="productDetail" data={data} handleClickCompare={handleClickCompare} />
        </Box>
        <Box mt={12}>
          <Grid id="box-wrapper" className="box-wrapper" xs={12} sm={12} md={12}>
            <Grid className='menuIcon' container justifyContent="flex-end">
              {
                (roles === 'Admin' || roles === 'SuperAdmin') && (
                  <EditAndDelete handleEditProduct={handleEditProduct} data={data} handleDeleteProduct={handleDeleteProduct} />
                )
              }
            </Grid>
            <Grid sx={{ padding: { md: "1px 40px 40px 40px", sm: "1px 20px 20px 20px", xs: "1px 20px 20px 20px" } }} container sm={12} md={12} xs={12} lg={12}>
              <Grid className="image-wrapper" md={6} sm={12} xs={12} lg={6}>
                <Grid container xs={12} sm={12} md={12}>
                  <Grid className="glb-image-container" xs={12} sm={10} md={9} lg={10}>
                    <Box className="glb-image">
                      {selectedImage === 'glb' ? (
                        <View3D autoplay={true} scrollable={true} tag="div" src={image?.glb} poster="/public/vercel.svg" />
                      ) : (
                        <img
                          onClick={() => setModal(true)}
                          width={300}
                          height={300}
                          className="main-image"
                          src={thumbImages?.[0]}
                          alt="Main Image"
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid className={'image-scroll-container'} mt={{ xs: 3, md: 0, sm: 0 }} justifyContent={{ xs: 'center' }} container xs={12} sm={2} md={2} lg={2}>
                    <Grid className={'scroll-content'} xs={12} >

                      {[...thumbImages.slice(0, 2), image?.glb ? 'glb' : null].filter(Boolean).map((img, index) => (
                        <Grid className="bottom-scroll-image" key={index}>
                          {img === 'glb' ? (
                            <div style={{ width: '100px', height: '100px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <ThreeDRotationIcon
                                sx={{ width: '50px', height: '50px', textAlign: 'center', cursor: 'pointer' }}
                                onClick={() => setSelectedImage('glb')}
                              />
                            </div>
                          ) : (
                            <img
                              onClick={() => setSelectedImage(img ?? '')}
                              width={100}
                              height={100}
                              className="slide-image"
                              src={img || ''}
                              alt={`Image ${index}`}
                            />
                          )}

                          {index === 2 && (
                            <Box onClick={() => setModal(true)} className="hover-container">
                              <Typography style={{ padding: '39px 10px 0px 10px' }}>
                                +{thumbImages.length - 3}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      ))}

                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="image-down-content" md={9}>
                  <Typography onClick={handleImageModal} className="label-wrapper-one">
                    Click to open expanded view
                  </Typography>
                </Grid>

                <Box className="vendor-content">
                  <Grid container xs={6} sm={8} md={8.5} >
                    <Typography className="label-wrapper" variant="subtitle2">Vendor Name</Typography>
                    <Typography textAlign={{ xs: 'initial' }} variant="subtitle1" ml={{ md: 2, sm: 2 }}>
                      {data?.vendorName || '-'}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent={{ md: "flex-end" }} xs={6} sm={4} md={3.5}>
                    <Typography ml={{ xs: 1 }} variant="h6">
                      ₹ {data?.price || 0} <span className="label-wrapper">per item</span>
                    </Typography>
                  </Grid>
                </Box>
              </Grid>

              {data?.category === 'rpt' && (
                < Grid p={{ md: 2.5, xs: 0, sm: 0 }} md={6} sm={12} xs={12} lg={6} >
                  <Grid ml={{ md: '10px' }} sx={{ textAlign: 'initial' }} container spacing={1}>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Code</Typography>
                      <Typography variant="subtitle1">{data?.productCode || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Product Availability</Typography>
                      <Typography variant="subtitle1">{data?.productAvailability || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Type</Typography>
                      <Typography variant="subtitle1">{data?.productType || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Best Suited For</Typography>
                      <Typography variant="subtitle1">{_.map(data?.bestSuite, function (list, key) {
                        return <> {list}{data?.bestSuite.length === key + 1 ? ' ' : ','} </>;
                      })}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Moulding Process</Typography>
                      <Typography variant="subtitle1">{data?.mouldingProcess || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">  Container Type  </Typography>
                      <Typography variant="subtitle1">{data?.containerType}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Material</Typography>
                      <Typography variant="subtitle1">{data?.material || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Shape</Typography>
                      <Typography variant="subtitle1">{data?.shapes}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">SKU Range</Typography>
                      <Typography variant="subtitle1">{data?.skuRange || '-'} {data?.skuUnit} </Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Over Fill Capacity</Typography>
                      <Typography variant="subtitle1">{data?.overFillCapacity || '-'} {data?.ofcUnit}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Weight</Typography>
                      <Typography variant="subtitle1">{data?.weight || '-'} {data?.weightUnit}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Price</Typography>
                      <Typography variant="subtitle1">{data?.price || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Decoration</Typography>
                      <Typography variant="subtitle1">{data?.decoration || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">MOQ</Typography>
                      <Typography variant="subtitle1">{data?.moq || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Colour</Typography>
                      <Typography variant="subtitle1">{data?.colour || '-'}</Typography>
                    </Grid>
                  </Grid>

                </Grid>)}

              {data?.category === 'gmt' && (
                < Grid p={{ md: 2.5, xs: 0, sm: 0 }} md={6} sm={12} xs={12} lg={6} >
                  <Grid ml={{ md: '10px' }} sx={{ textAlign: 'initial' }} container spacing={1}>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Code</Typography>
                      <Typography variant="subtitle1">{data?.productCode || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Product Availability</Typography>
                      <Typography variant="subtitle1">{data?.productAvailability || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Type</Typography>
                      <Typography variant="subtitle1">{data?.productType || '-'}</Typography>
                    </Grid>
                    {/* <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Suitable Closure</Typography>
                      <Typography variant="subtitle1">Screw on flip top</Typography>
                    </Grid> */}
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Best Suited For</Typography>
                      <Typography variant="subtitle1">{_.map(data?.bestSuite, function (list, key) {
                        return <> {list}{data?.bestSuite.length === key + 1 ? ' ' : ','} </>;
                      })}</Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Tube Type</Typography>
                      <Typography variant="subtitle1">{data?.tubeType || '-'}</Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Material</Typography>
                      <Typography variant="subtitle1">{data?.materialGMT || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">MOQ</Typography>
                      <Typography variant="subtitle1">{data?.moq || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Shapes</Typography>
                      <Typography variant="subtitle1">{data?.shapes}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">SKU Range</Typography>
                      <Typography variant="subtitle1">{data?.skuRange || '-'} {data?.skuUnit} </Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Over Fill Capacity</Typography>
                      <Typography variant="subtitle1">{data?.overFillCapacity || '-'} {data?.ofcUnit}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Weight</Typography>
                      <Typography variant="subtitle1">{data?.weight || '-'} {data?.weightUnit}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Price</Typography>
                      <Typography variant="subtitle1">{data?.price || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Decoration</Typography>
                      <Typography variant="subtitle1">{data?.decoration || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Colour</Typography>
                      <Typography variant="subtitle1">{data?.colour || '-'}</Typography>
                    </Grid>
                  </Grid>

                </Grid>)}

              {data?.category === 'flexy' && (
                < Grid p={{ md: 2.5, xs: 0, sm: 0 }} md={6} sm={12} xs={12} lg={6} >
                  <Grid ml={{ md: '10px' }} sx={{ textAlign: 'initial' }} container spacing={1}>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Code</Typography>
                      <Typography variant="subtitle1">{data?.productCode || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Product Availability</Typography>
                      <Typography variant="subtitle1">{data?.productAvailability || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Type</Typography>
                      <Typography variant="subtitle1">{data?.productType || '-'}</Typography>
                    </Grid>
                    {/* <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Suitable Closure</Typography>
                      <Typography variant="subtitle1">Screw on flip top</Typography>
                    </Grid> */}
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Best Suited For</Typography>
                      <Typography variant="subtitle1">{_.map(data?.bestSuite, function (list, key) {
                        return <> {list}{data?.bestSuite.length === key + 1 ? ' ' : ','} </>;
                      })}</Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Pouch Type</Typography>
                      <Typography variant="subtitle1">{data?.pouchType || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Material(Structure)</Typography>
                      <Typography variant="subtitle1">{data?.material || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Opening Mechanism</Typography>
                      <Typography variant="subtitle1">{data?.openingMechanism}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Shapes</Typography>
                      <Typography variant="subtitle1">{data?.shapes}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">SKU Range</Typography>
                      <Typography variant="subtitle1">{data?.skuRange || '-'} {data?.skuUnit} </Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Pouch Fill Capacity</Typography>
                      <Typography variant="subtitle1">{data?.pouchFillCapacity || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Spout Type & Size</Typography>
                      <Typography variant="subtitle1">{data?.spoutTypeSize || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Price</Typography>
                      <Typography variant="subtitle1">{data?.price || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">MOQ</Typography>
                      <Typography variant="subtitle1">{data?.moq || '-'}</Typography>
                    </Grid>
                  </Grid>

                </Grid>)}

              {data?.category === 'impression' && (
                < Grid p={{ md: 2.5, xs: 0, sm: 0 }} md={6} sm={12} xs={12} lg={6} >
                  <Grid ml={{ md: '10px' }} sx={{ textAlign: 'initial' }} container spacing={1}>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Code</Typography>
                      <Typography variant="subtitle1">{data?.productCode || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Product Availability</Typography>
                      <Typography variant="subtitle1">{data?.productAvailability || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8}>
                      <Typography className="label-wrapper">Product Type</Typography>
                      <Typography variant="subtitle1">{data?.productType || '-'}</Typography>
                    </Grid>
                    {/* <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Suitable Closure</Typography>
                      <Typography variant="subtitle1">Screw on flip top</Typography>
                    </Grid> */}
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Best Suited For</Typography>
                      <Typography variant="subtitle1">{_.map(data?.bestSuite, function (list, key) {
                        return <> {list}{data?.bestSuite.length === key + 1 ? ' ' : ','} </>;
                      })}</Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Carton Style</Typography>
                      <Typography variant="subtitle1">{data?.cartonStyle || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4} >
                      <Typography className="label-wrapper">Material</Typography>
                      <Typography variant="subtitle1">{data?.material || '-'}</Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Shape</Typography>
                      <Typography variant="subtitle1">{data?.shapes}</Typography>

                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">Price</Typography>
                      <Typography variant="subtitle1">{data?.price || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={8} md={8} >
                      <Typography className="label-wrapper">Decoration</Typography>
                      <Typography variant="subtitle1">{data?.decoration || '-'}</Typography>
                    </Grid>
                    <Grid mt={{ md: 2 }} item xs={6} sm={4} md={4}>
                      <Typography className="label-wrapper">MOQ</Typography>
                      <Typography variant="subtitle1">{data?.moq || '-'}</Typography>
                    </Grid>
                  </Grid>

                </Grid>)}

              <Grid p={{ md: 0, xs: 0, sm: 0 }} mt={{ xs: 1, sm: 1 }} item md={12} width={'10vw'}>
                <Typography textAlign={'initial'} className="label-wrapper">Product Description</Typography>
                <Typography variant="body2" >{data?.productDescription || '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>


        </Box >
      </Grid >
      <ImageSlick setModal={setModal} Images={imageHandle(data?.imageURLs)?.thumbImage} glbUrl={imageHandle(data?.imageURLs)?.glb} modal={modal} />
    </>
  );
};

export default ProductDetailsComponent;